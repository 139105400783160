import siteLogoImg from "../pictures/logo.png";

export const siteName = "LEMONDATA"; //this will display on sidebar
export const smsName = "lemondata"; // for bulk sms sending on behalf of the bussiness
export const appMainColor = "#fc5400"; // app's primary color
export const siteLogo = siteLogoImg; // site logo
export const baseApiUrl = "https://api.lemondata.com.ng/"; // for api call
export const contactNumber = "07036766778";
export const contactEmail = "support@lemondata.com.ng";
export const contactAddress = "No. 3 Pakali Close, Wuse 2 Abuja - Nigeria";
export const appname = "lemondata.apk";
export const appApkUrl = baseApiUrl + "download/" + appname;
export const monnifyApiParams =
  "MK_TEST_LRPY0VY5Q0:WJ7FGVFZ2P5PWD4LD2ZUVWG8FU137SLQ";
export const whatsappChat = "https://wa.me/+2347036766778";

// API key = MK_TEST_LRPY0VY5Q0
//Sectret Key = WJ7FGVFZ2P5PWD4LD2ZUVWG8FU137SLQ
