import "./Login.css";

import "./MobSupport.css";
import callUsImg from "../../../pictures/call_us_bg.png";
import helpHImg from "../../../pictures/help_h.png";
import helpImg from "../../../pictures/help.png";
import contactUsImg from "../../../pictures/contact_us.png";
import chatIco from "../../../pictures/chat.png";
import { MDBCol, MDBContainer, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import {
  contactAddress,
  contactEmail,
  contactNumber,
} from "../../../services/setup";
import ChatButton from "../components/ChatButton";

const MobileSupport = () => {
  return (
    <div
      style={{ height: "100vh" }}
      className="overlay d-flex flex-column align-items-center"
    >
      <img
        style={{
          position: "absolute",
          left: "20px",
          width: "200px",
          height: "150px",
          top: 0,
        }}
        src={helpHImg}
      />
      <img
        style={{
          position: "absolute",
          right: "50px",
          width: "200px",
          height: "150px",
          top: "120px",
        }}
        src={helpImg}
      />
      <section
        style={{
          position: "absolute",
          top: "300px",
          width: "85%",
          margin: "20px auto",
          padding: "20px",
          backgroundColor: "#fff",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* <h2>Need Support:</h2> */}
        <p>For inquiries and more information, please contact us at:</p>
        <address>
          <MDBIcon color="green" fas icon="envelope" /> {contactEmail}
          <br />
          <MDBIcon fas icon="phone" /> {contactNumber}
          <br />
          <MDBIcon fas icon="map-marked-alt" /> {contactAddress}
        </address>
      </section>

      <ChatButton />

      <div
        style={{
          position: "absolute",
          bottom: "40px",
          right: "0px",
          fontSize: "9px",
        }}
      >
        Chat us via whatsapp
      </div>
    </div>
  );
};

export default MobileSupport;
